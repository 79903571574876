<template>
  <div class="subPage">
    <div class="subPageTitle">
      <div class="subPageTitleText">
        <p>销售信息发布</p>
      </div>
      <div class="addButton">
        <el-button  type="danger" @click="showAddDialog()">新增销售信息</el-button>
      </div>
    </div>
    <div class="searchList">
      <el-row :gutter="10">
        <el-col :span="6">
          <el-col :span="6">
            <p class="searchItemTitle">产品名称</p>
          </el-col>
          <el-col :span="14">
            <el-input v-model="searchForm.commodityName" placeholder="请输入产品名称"></el-input>
          </el-col>
        </el-col>
        <el-col :span="4"></el-col>
        <el-col :span="6">
          <el-button type="danger" @click="getDatas()">搜索</el-button>
          <el-button @click="resetSearch()">重置</el-button>
        </el-col>
      </el-row>
    </div>
    <el-tabs class="tabList" v-model="activeTab" @tab-click="changeTab">
      <el-tab-pane label="全部" name="all"></el-tab-pane>
      <el-tab-pane label="已上架" name="pass"></el-tab-pane>
      <el-tab-pane label="待上架" name="fail"></el-tab-pane>
    </el-tabs>
  
    <div class="tableList">
      <!-- <div class="topData" @click="topClick">置顶</div> -->
      <el-table :data="tableDatas">
        <!-- （序号、商品品种、商品名称、价格、供货量、货物所在地、状态、发布时间） -->
        <!-- <el-table-column type="selection" width="55"  :selectable='isDisabled'></el-table-column> -->
        <!-- <el-table-column prop="supplyId" label="序号"></el-table-column> -->
        <!-- <el-table-column prop="supplyCommodityName" label="品种"></el-table-column> -->
        <el-table-column prop="commodityName" label="产品名称"></el-table-column>
        <el-table-column prop="price" label="单价">
            <template slot-scope="scope">
            <span>{{ scope.row.price }}</span>
          </template>
        </el-table-column>
        <!-- <el-table-column  label="重量">
            <template slot-scope="scope">
            <span>{{ scope.row.weight }}</span>
          </template>
        </el-table-column> -->
        <!-- <el-table-column prop="gpPrice" label="总价"></el-table-column> -->
        <el-table-column prop="packages" label="类型"></el-table-column>
        <el-table-column prop="contactsPhone" label="联系电话"></el-table-column>
        <el-table-column prop="companyName" label="企业名称"></el-table-column>
        <el-table-column prop="specifications" label="仓库"></el-table-column>
        <el-table-column prop="address" label="货物所在地"></el-table-column>
        <el-table-column prop="auditStatus" label="审核状态">
          <template slot-scope="scope">
            <span>{{ scope.row.status | cbSupplyNoteStatusFormate }}</span>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="orderStatus" label="是否撤销">
          <template slot-scope="scope">
            <span>{{ scope.row.orderStatus | isCancelOrder }}</span>
          </template>
        </el-table-column> -->
        <el-table-column prop="createTime" label="发布时间">
          <template slot-scope="scope">
            <span>{{ scope.row.createTime | DateFormateDay }}</span>
          </template>
        </el-table-column>  
        <el-table-column label="操作">
          <template slot-scope="scope">
            <!-- <el-button @click="showViewDialog(scope.row)" size="mini">查看</el-button> -->
            <el-button v-if="scope.row.status == 'W'||scope.row.status == 'T'"  @click="cancelResource(scope.row)" type="danger" size="mini">撤销</el-button>
            <!-- <el-button v-if="scope.row.status == 'T'" @click="topSupplyNote(scope.row)" type="danger" size="mini">置顶</el-button> -->
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="current"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalPage"
      ></el-pagination>
    </div>
    <!-- 查看 -->
    <viewDialog ref="viewDialog" />
    <!-- 新增 -->
    <addDialog ref="addDialog" @reLoad="getDatas()" />
    <!-- 修改 -->
    <!-- <editDialog ref="editDialog" @reLoad="getDatas()" /> -->
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import viewDialog from "./viewDialog";
import addDialog from "./addDialog";
import editDialog from "./editDialog";
export default {
  data() {
    return {
      multipleSelection:[],
      activeTab: "all",
      current: 1,
      pageSize: 10,
      totalPage: null,
      tableDatas: [],
      searchForm: {
        commodityName: null,
        statusStr: null,
        // orderStatus: null
      },
      right: {
        add: false,
        cancel: false
      }
    };
  },
  computed: {
    ...mapGetters(["sessionInfoGetter"])
  },
  components: {
    addDialog,
    editDialog,
    viewDialog
  },
  mounted() {
    this.getDatas();
    this.getRights();
  },
  methods: {
    getRights() {
      this.loadRight().then((loadResult) => {
        if (loadResult == 'loadRightSuccess') {
          this.right.add = this.isHasRight('trade-hang-order-for-margin-sell');
          this.right.cancel = this.isHasRight('trade-undo-order');
        }
      })
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getDatas();
    },
    handleCurrentChange(val) {
      this.current = val;
      this.getDatas();
    },
    resetSearch() {
      this.searchForm = {
        commodityName: null,
        statusStr  : null,
        // orderStatus: null
      };
      this.getDatas();
    },
    isDisabled(row){
      // 禁用复选框
      if(row.status==='T'){
        return 1; // 启用
      }else{
        return 0;
      }
    },
    changeTab(tab) {
      this.activeTab = tab.name;
      if (this.activeTab === "all") {
        this.searchForm.statusStr   = null;
        // this.searchForm.orderStatus = null;
      } else if (this.activeTab === "pass") {
        this.searchForm.statusStr   = ["T"];
        // this.searchForm.orderStatus = null;
      } else if (this.activeTab === "fail") {
        this.searchForm.statusStr   = ["W","F","U","D"];
        // this.searchForm.orderStatus = ["U"];
      }
      this.getDatas();
    },
    getDatas() {
      protocolFwd.param_queryCbSupplyNote.param = {
        page: this.current - 1,
        size: this.pageSize,
        sort: [],
        typeStr: "S",
        firmId: this.sessionInfoGetter.firmId,
        commodityName: this.searchForm.commodityName,
        statusStr: this.searchForm.statusStr,
        // orderStatus: this.searchForm.orderStatus
      };
      http.postFront(protocolFwd.param_queryCbSupplyNote).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          this.tableDatas = value.content;
          this.totalPage = value.totalElements;
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    topClick() {
      if(this.multipleSelection.length==0){
        this.$EL_MESSAGE('请选择要置顶的销售信息');
        return
      }
        this.$confirm("请确认是否已选择置顶销售信息？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          let supplyIdSelection = []
          for(let i=0;i<this.multipleSelection.length;i++){
            supplyIdSelection.push(this.multipleSelection[i].supplyId)
          }
          protocolFwd.param_topSupplyNote.param.supplyIdList = supplyIdSelection;
          protocolFwd.param_topSupplyNote.param.supplyId = null;
          // protocolFwd.param_undoSupplyNote.param.orderType = 0;
          http.postFront(protocolFwd.param_topSupplyNote).then(response => {
            const { code, message, value } = response.data;
            if (code == 0) {
              this.$EL_MESSAGE(message);
              this.getDatas();
            } else {
              this.$EL_MESSAGE(message);
            }
          });
        })
        .catch(() => {});
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
      console.log(this.multipleSelection)
    },
    showAddDialog() {
      this.$refs.addDialog.showDialog();
    },
    showEditDialog(row) {
      this.$refs.editDialog.showDialog(row);
    },
    showViewDialog(row) {
      this.$refs.viewDialog.showDialog(row);
    },
    cancelResource(row) {
      this.$confirm("请确认是否撤销该销售信息？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          protocolFwd.param_undoSupplyNote.param.supplyId = row.supplyId;
          // protocolFwd.param_undoSupplyNote.param.orderType = 0;
          http.postFront(protocolFwd.param_undoSupplyNote).then(response => {
            const { code, message, value } = response.data;
            if (code == 0) {
              this.$EL_MESSAGE(message);
              this.getDatas();
            } else {
              this.$EL_MESSAGE(message);
            }
          });
        })
        .catch(() => {});
    },
    topSupplyNote(row){
      this.$confirm("请确认是否置顶该销售信息？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          let suppList = []
          suppList.push(row.supplyId)
          protocolFwd.param_topSupplyNote.param.supplyIdList = suppList;
          // protocolFwd.param_undoSupplyNote.param.orderType = 0;
          http.postFront(protocolFwd.param_topSupplyNote).then(response => {
            const { code, message, value } = response.data;
            if (code == 0) {
              this.$EL_MESSAGE(message);
              this.getDatas();
            } else {
              this.$EL_MESSAGE(message);
            }
          });
        })
        .catch(() => {});
    }
  }
};
</script>
<style scoped lang="scss">
.subPage {
  padding: 0 15px;
}
.subPageTitle {
  overflow: hidden;
}
.subPageTitle .subPageTitleText {
  float: left;
  line-height: 40px;
}
.subPageTitle .subPageTitleText p {
  font-size: 16px;
}
.subPageTitle .addButton {
  float: right;
}
.searchList {
  margin: 10px 0;
}
.tabList {
  margin-top: 20px;
}
.tableList {
  position: relative;
  margin-top: 20px;
}
.topData{
    position: absolute;
    right: 27px;
    top: -54px;
    background-color: #f56c6c;
    padding: 5px 10px;
    color: #ffffff;
    border-radius: 5px;
}
.tableList {
  margin: 10px 0;
  /deep/ .el-table thead th{
    background-color: rgba(5, 64, 106, 1);
    color: #fff;
  }
  .allSelectBox{
    line-height: 30px;
    margin-left: 20px;
  }
}
.subPage .pagination {
  margin-top: 15px;
}
.searchItemTitle {
  line-height: 40px;
}
</style>
