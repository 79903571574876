<template>
  <el-dialog
    title="新增销售信息"
    :close-on-click-modal="false"
    :visible.sync="dialogVisible"
    :before-close="handleClose"
    width="900px"
  >
    <div>
      <!-- 品种、品名、价格、价格单位、、重量、重量单位、规格/牌号、货物所在地、包装方式、包装规格、联系人、联系电话、、职位、商品图片、企业介绍、产品详情 -->
      <el-form ref="form" :rules="rules" :model="form" label-position="top">
        <el-row :gutter="20">
          <!-- <el-col :span="12">
            <el-form-item label="品种" prop="supplyCommodityId">
              <el-select class="width-100" v-model="form.supplyCommodityId" placeholder="请选择品种" clearable >
                <el-option
                  v-for="(item,index) in categoryOptions"
                  :key="index"
                  :label="item.supplyCommodityName"
                  :value="item.supplyCommodityId"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col> -->
          <el-col :span="12" style="position:relative">
            <el-form-item label="产品名称" prop="commodityName">
              <el-input v-model="form.commodityName"></el-input>
            </el-form-item>
          </el-col>
    
          <el-col :span="12">
            <el-form-item label="价格" prop="price">
              <el-input v-model="form.price" placeholder="价格请输入**元/kg、**元/吨、面议等"></el-input>
            </el-form-item>
          </el-col>
           <!-- <el-col :span="12">
            <el-form-item label="价格单位" prop="unit">
              <el-input v-model="form.unit"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
         <el-row :gutter="20"> -->
          <!-- <el-col :span="12">
            <el-form-item label="重量" prop="weight">
              <el-input v-model="form.weight" placeholder="重量请输入**kg、**吨等"></el-input>
            </el-form-item>
          </el-col> -->
          <!-- <el-col :span="12">
            <el-form-item label="重量单位" prop="weightUnit">
              <el-input v-model="form.weightUnit"></el-input>
            </el-form-item>
          </el-col> -->
        <!-- </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="规格/牌号" prop="brand">
              <el-input v-model="form.brand" ></el-input>
            </el-form-item>
          </el-col> -->
           <el-col :span="12">
            <el-form-item label="货物所在地" prop="address">
              <el-input v-model="form.address"></el-input>
            </el-form-item>
          </el-col>
        
        <!-- </el-row>
        <el-row :gutter="20"> -->
          <el-col :span="12">
            <el-form-item label="类型" prop="packages">
              <el-select class="width-100" v-model="form.packages" placeholder="请选择类型" clearable >
                <el-option
                  v-for="(item,index) in packagesOptions"
                  :key="index"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
            <el-col :span="12">
            <el-form-item label="仓库" prop="specifications">
              <el-input v-model="form.specifications"></el-input>
            </el-form-item>
          </el-col>
<!--          
        </el-row>
        <el-row :gutter="20"> -->
          <el-col :span="12">
            <el-form-item label="企业名称" prop="companyName">
              <el-input v-model="form.companyName"></el-input>
            </el-form-item>
          </el-col>
            <!-- <el-col :span="12">
            <el-form-item label="职位" prop="position">
              <el-input v-model="form.position"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20"> -->
          <!-- <el-col :span="12">
            <el-form-item label="联系人" prop="contactsName">
              <el-input v-model="form.contactsName"></el-input>
            </el-form-item>
          </el-col> -->
          <el-col :span="12">
            <el-form-item label="联系电话" prop="contactsPhone">
              <el-input v-model="form.contactsPhone"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <!-- <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="商品图片" prop="img">
              <el-upload
                class="avatar-uploader"
                :action="actionUrl"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload"
                :data="postData"
              >
                <img v-if="form.img" :src="form.img" class="avatar" />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-form-item label="企业介绍" prop="enterpriseIntroduce">
            <Tinymce ref="Tinymces" v-model="form.enterpriseIntroduce"></Tinymce>
          </el-form-item>
        </el-row>
        <el-row :gutter="20">
          <el-form-item label="产品详情" prop="commodityIntroduce">
            <Tinymce ref="Tinymce" v-model="form.commodityIntroduce"></Tinymce>
          </el-form-item>
        </el-row> -->
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisibleFlag">取 消</el-button>
      <el-button type="primary" @click="confirm()" :loading="isAddLoading">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import Tinymce from "@/components/Tinymce/index.vue"
export default {
  data() {
    return {
      dialogVisible: false,
      form: {
        address: "", //货物存放地
        brand: "", // 规格/牌号
        commodityIntroduce: "", //商品介绍
        supplyCommodityId: 1, //商品品种
        commodityName: "", //商品名称
        companyName: "", // 公司名称
        contactsName: "", //联系人姓名
        contactsPhone: "", // 联系人电话
        enterpriseIntroduce: "", // 企业介绍
        img: "", //图片
        packages: "", // 包装方式
        position: "", // 职位
        price: null, // 价格
        specifications: "", //包装规格
        type: "",
        unit: "", //计量单位
        weight: null, //供货量
        weightUnit: "" //重量单位
      },
      rules: {
        address: [
          { required: true, message: "请输入货物所在地", trigger: "change" }
        ],
        price: [
          { required: true, message: "请输入价格", trigger: "change" }
        ],
        commodityName: [
          { required: true, message: "请输入产品名称", trigger: "change" }
        ],
        // supplyCommodityId: [
        //   { required: true, message: "请选择商品品种", trigger: "change" }
        // ],
        companyName: [
          { required: true, message: "请输入企业名称", trigger: "change" }
        ],
        contactsName: [
          { required: false, message: "请输入联系人姓名", trigger: "change" }
        ],
        contactsPhone: [
          { required: true, message: "请输入联系人电话", trigger: "change" },
          { pattern: /^1[3456789]\d{9}$/, message: "手机号格式不正确", trigger: "blur" }
        ],
        enterpriseIntroduce: [
          { required: false, message: "请输入企业介绍", trigger: "change" }
        ],
        packages: [
          { required: true, message: "请选择类型", trigger: "change" }
        ],
        brand: [
          { required: false, message: "请输入规格/牌号", trigger: "change" }
        ],
        specifications: [
          { required: true, message: "请输入仓库", trigger: "change" }
        ],
        weight: [
          { required: false, message: "请输入重量", trigger: "change" }
        ],
        img: [{ required: false, message: "请上传图片", trigger: "change" }],
        unit: [
          { required: false, message: "价格单位", trigger: "change" }
        ],
        weightUnit: [{ required: false, message: "重量单位", trigger: "change" }]
        // manufacturer: [
        //   { required: true, message: "请输入厂家", trigger: "change" }
        // ],
        // storageId: [{ required: true, message: "请选择仓库", trigger: "change" }],
      },
      categoryProps: {
        label: "supplyCommodityName",
        value: "supplyCommodityId"
      },
      packagesOptions:[{
        label: "现货",
        value: "现货"
      },{
        label: "预售",
        value: "预售"
      }],
      categoryOptions: [],
      gradeIdOptions: [],
      packagIdOptions: [],
      specificationsIdOptions: [],
      storageIdOptions: [],
      zjPriceCalc: null,
      isAddLoading: false,
      actionUrl: window.g.APP_USER_PATH_UPLOAD,
      postData: {
        uid: this.$store.state.common.sessionInfo.firmId,
        deleteStatus: 0,
        width: null,
        height: null,
        size: null
      },
      validButtonText: "发送验证码",
      validBtnDisabled: false
    };
  },
  watch: {
    // "form.weight": {
    //   handler(val, oldVal) {
    //     this.calcZjPrice();
    //   }
    // },
    // "form.quantity": {
    //   handler(val, oldVal) {
    //     this.calcWeight();
    //   }
    // }
  },
  components:{
    Tinymce
  },
  methods: {
    dialogVisibleFlag(){
      this.dialogVisible=false
      this.handleClose()
    },
    showDialog(row) {
      this.dialogVisible = true;
      // this.getCommodityCategory();
      this.getQuerySupplyVarieties();
      // this.getGradeIdOptions();
      // this.getPackagIdOptions();
      // this.getSpecificationsIdOptions();
      // this.getStorageIdOptions();
    },
    setTime(e, vueThis) {
      let _this = this;
      e--;
      vueThis.validButtonText = e + "s";
      if (e > 0) {
        setTimeout(function() {
          _this.setTime(e, vueThis);
        }, 1000);
      } else {
        vueThis.validButtonText = "发送验证码";
        vueThis.validBtnDisabled = false;
      }
    },
    sendValidNo() {
      protocol.param_authn_margin_sendCaptcha.param.id = 30;
      http.postFront(protocol.param_authn_margin_sendCaptcha).then(response => {
        this.$EL_MESSAGE(response.data.message);
        const that = this;
        this.validBtnDisabled = true;
        this.setTime(60, that);
      });
    },
    // getCommodityCategory() {
    //   http.postFront(protocolFwd.param_getCommodityCategory).then(response => {
    //     const { code, message, value } = response.data;
    //     if (code == 0) {
    //       this.deleteCategoryOptionsLast(value);
    //       this.categoryOptions = value;
    //     } else {
    //       this.$EL_MESSAGE(message);
    //     }
    //   });
    // },
    getQuerySupplyVarieties() {
      protocolFwd.param_querySupplyVarieties.param.type = 0;
      http.postFront(protocolFwd.param_querySupplyVarieties).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          // debugger
          this.categoryOptions = value;
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    deleteCategoryOptionsLast(data) {
      for (const item of data) {
        if (item.children.length !== 0) {
          this.deleteCategoryOptionsLast(item.children);
        } else {
          delete item.children;
        }
      }
    },
    getGradeIdOptions() {
      http.postFront(protocolFwd.param_getCommodityGrade).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          for (let i = 0; i < value.length; i++) {
            if (value[i].value == "国标") {
              this.gradeIdOptions.push(value[i]);
            }
          }
          this.form.gradeId = this.gradeIdOptions[0].value;
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    getPackagIdOptions() {
      http.postFront(protocolFwd.param_getCommodityPackage).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          this.packagIdOptions = value;
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    getSpecificationsIdOptions() {
      http.postFront(protocolFwd.param_getComSpecifications).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          this.specificationsIdOptions = value;
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    getStorageIdOptions() {
      http.postFront(protocolFwd.param_getStorage).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          this.storageIdOptions = value;
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    calcWeight() {
      let specificationsValue = null;
      for (let i = 0; i < this.specificationsIdOptions.length; i++) {
        if (
          this.form.specificationsId ==
          this.specificationsIdOptions[i].specificationsId
        ) {
          specificationsValue = parseFloat(
            this.specificationsIdOptions[i].value
          );
        }
      }
      this.form.weight = util.floatMul(this.form.quantity, specificationsValue);
    },
    calcZjPrice() {
      this.zjPriceCalc = util.floatMul(this.form.price, this.form.weight);
    },
    handleAvatarSuccess(res, file) {
      this.form.img = res.value;
    },
    beforeAvatarUpload(file) {
      const testmsg = /^image\/(jpeg|png|jpg)$/.test(file.type);
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!testmsg) {
        this.$EL_MESSAGE.error("上传图片格式不对!");
        return;
      }
      if (!isLt2M) {
        this.$EL_MESSAGE.error("上传图片大小不能超过 2MB!");
      }
      return testmsg && isLt2M;
    },
    confirm() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          // if (Array.isArray(this.form.commodityTypeId)) {
          //   this.form.commodityTypeId = this.form.commodityTypeId[
          //     this.form.commodityTypeId.length - 1
          //   ];
          // } else {
          //   this.form.commodityTypeId = this.form.commodityTypeId;
          // }
          protocolFwd.param_releaseSupplyNote.param = this.form;
          // protocolFwd.param_hangOrderForMarginToSell.param.sourceId = "C";
          protocolFwd.param_releaseSupplyNote.param.type = "S";
          this.isAddLoading = true;
          http
            .postFront(protocolFwd.param_releaseSupplyNote)
            .then(response => {
              const { code, message, value } = response.data;
              this.isAddLoading = false;
              if (code == 0) {
                this.dialogVisible = false;
                this.$EL_MESSAGE("提交成功！待后台审核成功后方可发布");
                this.$refs["form"].resetFields();
                this.$emit("reLoad");
                this.handleClose()
              } else {
                this.$EL_MESSAGE(message);
              }
            })
            .catch(() => {
              this.isAddLoading = false;
            });
        }
      });
    },
    handleClose() {
      this.form = {
        address: "", //货物存放地
        brand: "", // 规格/牌号
        commodityIntroduce: null, //商品介绍
        supplyCommodityId: 1, //商品品种
        commodityName: "", //商品名称
        companyName: "", // 公司名称
        contactsName: "", //联系人姓名
        contactsPhone: "", // 联系人电话
        enterpriseIntroduce: null, // 企业介绍
        img: "", //图片
        packages: "", // 包装方式
        position: "", // 职位
        price: null, // 价格
        specifications: "", //包装规格
        type: "",
        unit: "", //计量单位
        weight: null, //供货量
        weightUnit: "" //重量单位
      };
      if (this.$refs.Tinymce) {
        this.$refs.Tinymce.setContent('')
      }
       if (this.$refs.Tinymces) {
        this.$refs.Tinymces.setContent('')
      }
      this.$nextTick(() => {
        this.$refs["form"].clearValidate();
      });
       this.$nextTick(() => {
        if (this.form.content) {
          this.$refs.Tinymce.setContent(this.form.content)
        }
        if (this.form.content) {
          this.$refs.Tinymces.setContent(this.form.content)
        }
      })
     
      this.dialogVisible = false;
    }
  }
};
</script>
<style lang="scss" scoped>
.tip {
  color: #cf4640;
  position: absolute;
  top: 9px;
  left: 66px;
}
</style>