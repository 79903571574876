<template>
  <el-dialog
    title="销售信息详情"
    :close-on-click-modal="false"
    :visible.sync="dialogVisible"
    :before-close="handleClose"
    width="900px"
  >
   <!-- 品种、品名、价格、价格单位、、重量、重量单位、规格/牌号、货物所在地、包装方式、包装规格、联系人、联系电话、、职位、商品图片、企业介绍、产品详情 -->
    <div class="viewDetails">
       <el-row :gutter="20">
        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">品名：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.commodityName }}</p>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">品种：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.supplyCommodityName }}</p>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">单价：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.price }}</p>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">重量:</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.weight }}</p>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
     <el-row :gutter="20">
       <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">规格/牌号：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.brand }}</p>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="12">
             <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">货物所在地: </p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.address }}</p>
            </el-col>
          </el-row>
        </el-col>  
      </el-row>
      <el-row :gutter="20">
         <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">包装方式：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.packages }}</p>
            </el-col>
          </el-row>
        </el-col>
         <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">包装规格：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.specifications }}</p>
            </el-col>
            </el-row>
            </el-col>
      </el-row>
       <el-row :gutter="20">
          <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">公司名称:</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.companyName }}</p>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">职位：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.position }}</p>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
      
    
       <el-row :gutter="20">
        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">联系人: </p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.contactsName }}</p>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">联系电话：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.contactsPhone }}</p>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
      
      <el-row :gutter="20">
       
         <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">图片：</p>
            </el-col>
            <el-col :span="16">
              <img class="proPictureImg" v-if="form.img !== '--'" :src="form.img" />
              <p v-else>--</p>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="24">
          <el-row :gutter="20">
            <el-col :span="4">
              <p class="title">企业介绍：</p>
            </el-col>
            <el-col :span="20" style="overflow:hidden">
              <p class="text" v-html="form.enterpriseIntroduce"></p>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="24">
          <el-row :gutter="20">
            <el-col :span="4">
              <p class="title">产品详情：</p>
            </el-col>
            <el-col :span="20" style="overflow:hidden">
              <p class="text" v-html="form.commodityIntroduce"></p>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
export default {
  data() {
    return {
      dialogVisible: false,
      form: {
        warehousId: null,
        price: null
      }
    };
  },
  methods: {
    showDialog(row) {
      this.dialogVisible = true;
      this.getDetails(row.supplyId);
    },
    getDetails(id) {
      protocolFwd.param_queryCbSupplyNoteDetails.param.supplyId = id;
      http
        .postFront(protocolFwd.param_queryCbSupplyNoteDetails)
        .then(response => {
          const { code, message, value } = response.data;
          if (code == 0) {
            this.form = value;
            for (let i in this.form) {
              if (!this.form[i]&&this.form[i]!=0) {
                this.form[i] = "--";
              }
            }
          } else {
            this.$EL_MESSAGE(message);
          }
        });
    },
    handleClose() {
      this.dialogVisible = false;
    }
  }
};
</script>
<style scoped>
.viewDetails {
  line-height: 40px;
}
.viewDetails .title {
  text-align: right;
}
.proPictureImg {
  max-width: 80%;
}
</style>